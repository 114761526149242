var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "view-producer" }, [
    _c("header", { staticClass: "header" }, [
      _vm._m(0),
      _c(
        "ul",
        { staticClass: "variables" },
        [
          _vm._v("Use these words to define variables in the contract:"),
          _vm._l(_vm.variables, function (value, key) {
            return _c("li", { key: key }, [_vm._v(_vm._s(value))])
          }),
        ],
        2
      ),
    ]),
    _c("section", { staticClass: "section" }, [
      _vm.isLoading || _vm.licenseOptionsLight.length === 0
        ? _c(
            "div",
            { staticClass: "container container--fullWidth" },
            [_c("VLoadSpinner")],
            1
          )
        : _c("div", { staticClass: "container container--fullWidth" }, [
            _c(
              "ul",
              { staticClass: "license-options" },
              _vm._l(_vm.licenseOptionsLight, function (license, index) {
                return _c(
                  "li",
                  {
                    key: license.id,
                    class: { active: license.id === _vm.activeLicense.id },
                    on: {
                      click: function ($event) {
                        return _vm.setActiveLicense(license)
                      },
                    },
                  },
                  [_vm._v(_vm._s(license.name))]
                )
              }),
              0
            ),
            _c(
              "div",
              { staticClass: "wrapper--filled text-editor" },
              [
                _vm._l(_vm.licenseOptionsLight, function (license) {
                  return _c("quill-editor", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: license.id === _vm.activeLicense.id,
                        expression: "license.id === activeLicense.id",
                      },
                    ],
                    key: license.id,
                    attrs: {
                      content: license.contract_html,
                      options: _vm.editorOption,
                    },
                    on: {
                      change: function ($event) {
                        return _vm.onEditorChange($event, license)
                      },
                    },
                  })
                }),
                _c(
                  "a",
                  {
                    staticClass:
                      "btn btn--sm btn--black btn--text default-template",
                    on: { click: _vm.setDefaultContract },
                  },
                  [_vm._v("Load Default Template")]
                ),
              ],
              2
            ),
          ]),
    ]),
    _c("section", { staticClass: "section__bottom-button" }, [
      _c(
        "a",
        { staticClass: "btn btn--primary", on: { click: _vm.saveContracts } },
        [_vm._v("SAVE CHANGES")]
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "container headerContainer" }, [
      _c("div", { staticClass: "headerContainer__heading" }, [
        _c("h1", [_vm._v("Contracts")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }